import React, { useContext } from "react";
import Cookies from "js-cookie";
import {
  AppGeneralTextContext,
} from "./app_context/app_context";
import { useNavigate } from "react-router-dom";

const ContactABB: React.FC = () => {
  const appGeneralTextData = useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const navigate = useNavigate();

  const handleRaiseTicket = () => {
    const userDataCookie = Cookies.get("user_data");
    const user_data = userDataCookie ? JSON.parse(userDataCookie) : null;
    if (user_data) {
      navigate("/contact_abb/casecreationform", {
        state: {
          userName: `${user_data.given_name} ${user_data.family_name}`,
          email: user_data.email,
        },
      });
    }
  };
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="role-deinition-screen">
      <h1>{general_text_data.contact_abb}</h1>
      <div className="new-role-container">
        <div className="contact-abb">
          <div className="background-overlay"></div>
          <div className="grid-container contact-abb-container">
            <div className="col-7">
              <img
                src="/assets/images/contact_abb_group.svg"
                alt="Engineers"
                className="contact-abb-image"
              />
            </div>

            <div className="col-5 contact-abb-card-list">
              <div
                className="contact-abb-card"
                onClick={() =>
                  navigate(
                    "/platform_administration/roles_user_management/user_mapping/invite_new_user",
                    { state: { type: "user_self_request" } }
                  )
                }
              >
                <div className="image-container">
                  <div>
                    <img
                      src={`/assets/images/icon_contact_service_pro.svg`}
                      alt="invite-new-user"
                      style={{
                        filter: "var(--icon-filter-header)",
                      }}
                    />
                  </div>
                </div>
                <span>
                  {general_text_data.request_for_extension_application}
                </span>
              </div>
              <div
                className="contact-abb-card"
                onClick={() => navigate("/serviceproAccessRequest")}
              >
                <div className="image-container">
                  <div>
                    <img
                      src={`/assets/images/icon_contact_request.svg`}
                      alt="invite-new-user"
                      style={{
                        filter: "var(--icon-filter-header)",
                      }}
                    />
                  </div>
                </div>
                <span>
                  {general_text_data.request_for_service_pro_application}
                </span>
              </div>
              <div className="contact-abb-card" onClick={handleRaiseTicket}>
                <div className="image-container">
                  <div>
                    <img
                      src={`/assets/images/icon_rise_ticket.svg`}
                      alt="invite-new-user"
                      style={{
                        filter: "var(--icon-filter-header)",
                      }}
                    />
                  </div>
                </div>
                <span>{general_text_data.raise_the_ticket_form_to_case}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactABB;
