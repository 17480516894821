import React, { useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { ImagesContext } from "./app_context/app_context";
import { MUIButton, MUITextField } from "@abb/abb-arcadia-ui-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const MyQcs = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { general_text_data }: any = appGeneralTextData;
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };
  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>My Learning</h1>
      </div>

      <div className="container">
        <div className="webinars-section">
          <div className="heading_section">Webinars</div>
          <div className="webinar-list">
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img src={images.img_papper_making} alt="Papermaking Process" />
              </div>
              <div className="col-6 webinar-content">
                <div className="webinars-section_content-heading">
                  The Paper Making Process
                </div>
                <div className="webinar-content_des webinars-section_papermaking-content-des">
                  Pulp and paper making are complex, continuous processes. We
                  break both down in a digestible way to understand the many
                  opportunities we have to support our customers.
                </div>
                <a href="#">Watch on-demand Webinar</a>
              </div>
            </div>
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img src={images.img_abb_solution} alt="Solutions for Pulp" />
              </div>
              <div className="col-6 webinar-content">
                <div className="webinars-section_content-heading">
                  ABB’S Solutions for Pulp, Paper, Board and Tissue
                </div>
                <div className="webinar-content_des webinars-section_solutions-des">
                  With 366 active product tree nodes – we have a lot to offer
                  our customers! Learn the breadth of the portfolio to
                  understand what solutions you could be offering customers and
                  / or promoting in your local markets! 
                </div>
                <a href="#">Watch on-demand Webinar</a>
              </div>
            </div>
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img
                  src={images.img_pulp_papper_training}
                  alt="Training & Launches"
                />
              </div>
              <div className="col-6 webinar-content">
                <div className="webinars-section_content-heading">
                  Pulp & Paper Training, Recordings & Launches
                </div>
                <div className="webinar-content_des webinars-section_recording-des">
                  Stay up to date on the latest from ABB Pulp & Paper and catch
                  up on all of our training sessions, webinar recordings, and
                  product launches.
                </div>
                <a
                  href="https://go.insideplus.abb.com/business-areas-and-divisions/process-automation/division-portals/process-industries/business-lines/business-line-pulp-and-paper/internal-webinars-product-launches"
                  target="_blank"
                >
                  Visit our internal launch hub
                </a>
              </div>
            </div>
          </div>
          <div className="webinar_section_btn view_more_btn">
            <MUIButton
              label="View More"
              // onClickButton={handleNewApplicationClick}
              size={32}
              variant="primary"
            />{" "}
          </div>
        </div>
        <div className="trainings-section">
          <div className="heading_section">Trainings</div>
          <div className="training-box">
            <div className="trainings-section_us_training trainings-section_ie-training">
              Training Schedule
            </div>
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index: any) => handleTabClick(index)}
            >
              <div className="my_learning_tabs">
                <TabList className="react-tabs__tab-list tab-container-tabItems">
                  <Tab key={"united states"}>{"United States"}</Tab>
                  <Tab key={"Ireland"}>{"Ireland"}</Tab>
                </TabList>
              </div>
            </Tabs>
            <div className="trainings-section_sub_heading">
              <p>My Pulp & Paper Trainings</p>
              <a
                href="https://go.insideplus.abb.com/business-areas-and-divisions/process-automation/division-portals/process-industries/business-lines/business-line-pulp-and-paper/pulp-paper-sales-support#portfolio"
                target="_blank"
              >
                https://go.insideplus.abb.com/business-areas-and-divisions/
                process-automation/division-portals/process-industries/business-lines/
                business-line-pulp-and-paper/pulp-paper-sales-support#portfolio
              </a>
            </div>
          </div>
          <div className="training_ie_section">
            <div className="trainings-section_us_training trainings-section_ie-training">
              Available Courses
            </div>
            <div className="grid-container trainings-section_item gap-5">
              <div className="col-6">
                <div className="trainings-section_us_training">
                  Course Registration
                </div>
              </div>
              <div className="col-6">
                <div className="trainings-section_us_training">
                  Course Descriptions
                </div>
              </div>
            </div>
            <div className="table-column">
              <div className="header-bg-blue">Controls</div>
              <div className="grid-container gap-10">
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/22987_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C103 - QCS Machine-Direction Control - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23085_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C109 - QCS Multivariable CD Control - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/33282_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C150 - QCS Advanced Color Control - IE
                    </a>
                  </div>
                </div>
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    {" "}
                    <a
                      href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095099&LanguageCode=en&DocumentPartId=&Action=Launch"
                      target="_blank"
                    >
                      C103 Course Description
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095100&LanguageCode=en&DocumentPartId=&Action=Launch"
                      target="_blank"
                    >
                      C105 Course Description
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-column">
              <div className="header-bg-blue">Systems</div>
              <div className="grid-container gap-10">
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/22988_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C302 - QCS800xA Essentials - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23088_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C306 - QCS800xA Advanced Concepts - IE
                    </a>
                  </div>
                </div>
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095101&LanguageCode=en&DocumentPartId=&Action=Launch"
                      target="_blank"
                    >
                      C107 Course Description
                    </a>
                  </div>
                  <div className="p-5">
                    <a href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A1835&LanguageCode=en&DocumentPartId=&Action=Launch">
                      C109 QCS Multivariable Cross Direction Control
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-column">
              <div className="header-bg-blue">Scanners & Sensors</div>
              <div className="grid-container gap-10">
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23098_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C235 - Network Platform with QCS LAN - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/22986_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C315 - High Performance Sensor - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/38818_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C311 - QCS Sensor Correlation - IE
                    </a>
                  </div>
                </div>
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS222456-1&LanguageCode=en&DocumentPartId=&Action=Launch"
                      target="_blank"
                    >
                      C302 ABB QCS800xA Essentials
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A1055&LanguageCode=en&DocumentPartId=&Action=Launch"
                      target="_blank"
                    >
                      C306 ABB QCS800xA Advanced Concepts
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-column">
              <div className="header-bg-blue">Actuators</div>
              <div className="grid-container gap-10">
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C400 - Weight xP II Profiler - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C400 - Weight xP II Profiler - IE
                    </a>
                  </div>
                </div>
                <div className="col-6 p-lr-8">
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C400 - Weight xP II Profiler - IE
                    </a>
                  </div>
                  <div className="p-5">
                    <a
                      href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1"
                      target="_blank"
                    >
                      C400 - Weight xP II Profiler - IE
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="view_more_btn pt-20">
              <MUIButton
                label="View More"
                // onClickButton={handleNewApplicationClick}
                size={32}
                variant="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyQcs;
