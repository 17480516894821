import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Space } from "antd";
import { Checkbox } from "antd";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { SEARCH_TRIGGER_LENGTH } from "./components/constants";
import BannerImage from "./banner_image";
import { getAllApplicationConfig } from "./services/myPnpServices";
import { Input, Button } from "@abb/abb-common-ux-react";
import { MUITextField } from "@abb/abb-arcadia-ui-react";

const MyApps = () => {
  const navigate = useNavigate();
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;

  const [serviceData, setServiceData] = useState<any>([]);
  const [extensionData, setExtensionData] = useState<any>([]);
  const [platformData, setPlatformData] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortEnabled, setSortEnabled] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [categories, setCatgories] = useState<any>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [checkedCategory, setCheckedCategory] = useState<string[]>([]);
  const [permitted, setPermitted] = useState(true);

  useEffect(() => {
    fetchAllAppsConfig();
  }, []);

  const fetchAllAppsConfig = async () => {
    try {
      const serviceResp = await getAllApplicationConfig(1);
      if (serviceResp.length > 0) {
        const sortedServiceApps = sortApps(serviceResp);
        setServiceData(sortedServiceApps);
      } else {
        setServiceData([]);
      }

      const extensionResp = await getAllApplicationConfig(2);
      if (extensionResp.length > 0) {
        const sortedExtensionApps = sortApps(extensionResp);
        setExtensionData(sortedExtensionApps);
      } else {
        setExtensionData([]);
      }

      const platformResp = await getAllApplicationConfig(3);
      const newEntry = {
        appRegid: "Application provision",
        name: "Application Provisioning",
        description:
          "Setting up and configuring applications on My Pulp and Paper platform to ensure they are ready for use by end-users.",
        applicationSecurityId: null,
        applicationTypeId: 3,
        applicationTypeName: "Platform Administrator",
        adclient: null,
        embedUrl:
          "/platform_administration/manage_applications/Application_Provisioning",
        externalUrl: "",
        icon: "icon_applications_provision.svg",
        isActive: true,
        newTab: false,
        valuePillar: "Platform Administrator",
        tileOrder: 4,
      };
      // Add the new entry to the response array
      platformResp.push(newEntry);
      if (platformResp.length > 0) {
        const sortedPlatformApps = sortApps(platformResp);
        setPlatformData(sortedPlatformApps);
      } else {
        setPlatformData([]);
      }
    } catch (error) {
      setServiceData([]);
      setExtensionData([]);
      setPlatformData([]);
    }
  };
  const sortApps = (data: any) => {
    return data.sort((a: any, b: any) => {
      if (a.tileOrder === b.tileOrder) {
        return a.name.localeCompare(b.name);
      }
      return a.tileOrder - b.tileOrder;
    });
  };
  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
    setSearchInput(value.length > SEARCH_TRIGGER_LENGTH ? value : "");
  };
  const toggleSortOrder = () => {
    setSortEnabled(true);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  const onCategoryFilterChange = (e: any, cat: any) => {
    setCheckedCategory((selectedCat: any) => {
      if (e.target.checked) {
        return [...selectedCat, cat];
      } else {
        return selectedCat.filter((item: any) => item !== cat);
      }
    });
  };
  const onSetFilters = () => {
    setShowFilter(false);
  };
  const onClearFilters = () => {
    setCheckedCategory([]);
  };

  const renderFilterContent = () => {
    return (
      <div className="filter-content">
        <span className="filter-content-title">
          {general_text_data.filters}
        </span>
        <hr />
        <div className="filter-content-button-container">
          <div>
            <Button className="filter-button filter-button-left">
              {general_text_data.all_applications}
            </Button>
          </div>
          <div>
            <Button className="filter-button filter-button-right">
              {general_text_data.favourites}
            </Button>{" "}
          </div>
        </div>
        <div className="filter-content-category">
          <span className="filter-content-category-title">
            {general_text_data.filter_by_category}
          </span>
          <div className="filter-content-category-checkbox">
            {categories.map((cat: any) => {
              return (
                <Checkbox
                  onChange={(e) => onCategoryFilterChange(e, cat)}
                  checked={checkedCategory.includes(cat)}
                >
                  {cat}
                </Checkbox>
              );
            })}
          </div>
        </div>
        <div className="filter-content-footer">
          <div className="filter-content-footer-link">
            <Button onClick={onClearFilters}>
              {general_text_data.clear_all}
            </Button>
          </div>
          <div className="filter-content-footer-buttons">
            <Button onClick={() => setShowFilter(false)}>
              {general_text_data.cancel}
            </Button>
            <Button onClick={onSetFilters}>{general_text_data.apply}</Button>
          </div>
        </div>
      </div>
    );
  };
  const AppBlock = ({ data }: any) => {
    return (
      <div className="blocks" key={data.id}>
        <div className="app-info">
          <div className="image-container">
            <div>
              <img
                src={`../assets/images/${data.icon}`}
                alt={data.name}
                style={{
                  filter: "var(--icon-filter-header)",
                }}
              />
            </div>
          </div>
          <div className="app-name">
            <h4>{data.name}</h4>
          </div>
        </div>
        <div className="box-content">
          <p
            title={data?.description.length > 95 ? data.description : undefined}
          >
            {data?.description.length > 95
              ? data.description.slice(0, 95) + " ..."
              : data.description}
          </p>
        </div>
        <div className="app-link">{general_text_data.go_to_app}</div>
      </div>
    );
  };
  function containsDomain(url: string) {
    const domainPattern = /https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    return domainPattern.test(url);
  }
  const renderApplications = (applicationData: any, category: string) => {
    if (applicationData.length === 0) {
      return (
        <div className="empty-message myapp-headings">
          You dont have permission to view the {category} applications, please
          contact Administrator.
        </div>
      );
    }
    return (
      <div className="p-18">
        <div className=" grid-container">
          {applicationData !== undefined &&
            applicationData.length > 0 &&
            applicationData
              .filter((app: any) =>
                app.name.toLowerCase().includes(searchInput.toLowerCase())
              )
              .sort((a: any, b: any) => {
                if (!sortEnabled) return 0;
                const comparison = a.name.localeCompare(b.name);
                return sortOrder === "asc" ? comparison : -comparison;
              })
              .map((data: any) => {
                let url = data.externalUrl || data.embedUrl;
                const window_url = window.location.href;
                if (window_url.includes("dev.in")) {
                  url = url.replace("stage.in", "dev.in");
                }
                const isExternal = containsDomain(url);
                return (
                  <div className="blocks-container col-3">
                    {isExternal ? (
                      <Link
                        to={url}
                        reloadDocument={true}
                        target={data.newTab ? "_blank" : "_self"}
                      >
                        <AppBlock data={data} />
                      </Link>
                    ) : (
                      <div className="app-block" onClick={() => navigate(url)}>
                        <AppBlock data={data} />
                      </div>
                    )}
                  </div>
                );
              })}
        </div>
      </div>
    );
  };
  return (
    <div className="my-app-section">
      <h1>My Apps</h1>
      <div className="banner-container">
        <BannerImage />
      </div>
      <div className="intro-content-myapps">
        {general_text_data.description_home}
      </div>

      <div className="services-content">
        <div className="misc-actions-container mr-20">
          <div className="cta-btns">
            <Space>
              <div className="input-group">
                <MUITextField
                  onChange={handleSearchChange}
                  onKeyDown={() => {}}
                  placeholder={"Search for Application"}
                  size={32}
                />
              </div>
              {/* {permitted === true && (
                <Button
                  sizeClass="small"
                  type="primary-blue"
                  shape={"pill"}
                  text={general_text_data.add_applications}
                  icon={"abb/plus"}
                  onClick={() =>
                    (window.location.href =
                      "/platform_administration/manage_applications/Application_Provisioning")
                  }
                />
              )} */}
              {/* <Popover
                content={renderFilterContent}
                placement="bottomLeft"
                title=""
                trigger="click"
                open={showFilter}
                onOpenChange={() => setShowFilter(false)}
              >
                <div
                  className="icon-filter"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <img src={`${images.filter_icon}`} alt="" />
                </div>
              </Popover> */}

              <div className="icon-sort" onClick={toggleSortOrder}>
                <img
                  className="icon-sort"
                  src={
                    sortOrder === "asc"
                      ? `${images.sort_icon_asc}`
                      : `${images.sort_icon_dsc}`
                  }
                  alt=""
                  title={
                    sortOrder === "asc"
                      ? "Sort in descending order"
                      : "Sort in ascending order"
                  }
                  style={{ filter: "var(--icon-filter-header)" }}
                />
              </div>
            </Space>
          </div>
        </div>
        <h3 className="myapp-headings">Service Apps</h3>
        {renderApplications(serviceData, "Service Apps")}
        <h3 className="myapp-headings">Extensions</h3>
        {renderApplications(extensionData, "Extensions")}
        <h3 className="myapp-headings">Platform Administration</h3>
        {renderApplications(platformData, "Platform Administration")}
      </div>
    </div>
  );
};

export default MyApps;
