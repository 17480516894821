import React, { useState, useEffect, useCallback } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { getAllApplications } from "./services/myPnpServices";
import { useNavigate } from "react-router-dom";
import { MUISelect } from "@abb/abb-arcadia-ui-react";
import { sessionTimeoutvalue, getSessionTimeoutValue } from "./services/myPnpServices"
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, getDropdownValue } from "./utils/utils";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react";
import path from "path";

const SessionControl = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [permitted, setPermitted] = useState(true);
  const [selectedOption, setSelectedOption] = useState<string>("sessionExpiry")
  const [expiryHour, setExpiryHour] = useState<any>()
  const [showPermissionModal, setShowPermissionModal] = useState(false); // New state for modal visibility
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  useEffect(() => {
    callGetSessionTimeoutValue()
    callGetAllApplications();
  }, []);

  const callGetSessionTimeoutValue = async () => {
    try {
      const result = await getSessionTimeoutValue();
      let txt = result / 3600 === 1 ? "Hour" : "Hours"
      setExpiryHour({
        name: result / 3600 + ' ' + txt,
        id: result / 3600
      })
    } catch (error) {
      console.log(error)
    }
  }

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const sessonHours = [
    {
      id: "1",
      name: "1 Hour",
    },
    {
      id: "2",
      name: "2 Hours",
    },
    {
      id: "3",
      name: "3 Hours",
    },
    {
      id: "4",
      name: "4 Hours",
    },
    {
      id: "5",
      name: "5 Hours",
    },
    {
      id: "6",
      name: "6 Hours",
    },
    {
      id: "7",
      name: "7 Hours",
    },
    {
      id: "8",
      name: "8 Hours",
    },
    {
      id: "9",
      name: "9 Hours",
    },
    {
      id: "10",
      name: "10 Hours",
    }
  ];

  const handleSave = async () => {
    if (parseInt(expiryHour.id) === 0) {
      showNotification([buildNotification("Session Timeout Hours required!", "alarm")]);
    } else {
      const result = await sessionTimeoutvalue(parseInt(expiryHour.id) * 3600)
      if (result) {
        callGetSessionTimeoutValue()
        showNotification([buildNotification("Session Timeout Hours updated successfully!", "success")]);
      }
    }
  }

  const handleSelection = (event: any) => {
    setSelectedOption(event.target.value)
  };

  const handleCancel = () => {
    navigate("/platform_administration")
  }

  return (
    <div className="session-control-section">
      {!permitted &&
        showPermissionModal && ( // Conditional rendering of the modal
          <div className="modal-overlay">
            <div className="modal red-border">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => {
                    navigate("/home");
                  }}                >
                  &times;
                </button>
                <p>
                  You don’t have the required permissions to access Platform
                  Administration Application. Please contact the administrator.
                </p>
              </div>
            </div>
          </div>
        )}
      {permitted === true && (
        <>
          <h1 className="session-control-heading">{general_text_data.session_control}</h1>
          <div className="session-control-container">
            <h4 className="session-control-h4">{general_text_data.select_session_type}</h4>
            <p>{general_text_data.session_type}</p>
            <div className="session-options">
              <div className="opt1">
                <div className="formEle">
                  <div className="input-holder">
                    <input type="radio" value="NoActivityTimeout" checked={selectedOption === "NoActivityTimeout"} disabled />
                  </div>
                  <div className="label-holder">
                    {general_text_data.no_activity_timeout}
                  </div>
                </div>
                <p>{general_text_data.no_activity_timeout_desc}</p>
              </div>
              <div className="opt2">
                <div className="formEle">
                  <div className="input-holder">
                    <input type="radio" value="sessionExpiry" checked={selectedOption === "sessionExpiry"} onChange={(e) => handleSelection(e)} />
                  </div>
                  <div className="label-holder">
                    {general_text_data.session_expiry}
                  </div>
                </div>
                <p>{general_text_data.session_expiry_desc}</p>
              </div>
            </div>
            {selectedOption === "sessionExpiry" ? (
              <div className="session-timing-container">
                <div>
                  <h4>{general_text_data.session_timeout} :</h4>
                </div>
                <div>

                  <Dropdown
                    menuPlacement="bottom"
                    onChange={(val: any) => {
                      setExpiryHour({
                        name: val[0].label,
                        id: val[0].value
                      });
                    }}
                    value={expiryHour &&
                      getDropdownValue(
                        expiryHour.name,
                        expiryHour.id
                      )
                    }
                    placeholder="Please Select"
                    className="hours-dd"
                    multiselect={false}
                  >
                    {sessonHours?.map((item: any) => {
                      return (
                        <DropdownOption
                          key={item.id}
                          label={item.name}
                          value={item.id}
                        />
                      );
                    })}
                  </Dropdown>
                </div>
              </div>
            ) : null

            }

            <div className="session-control-buttons">
              <ABB.Button
                text={"Cancel"}
                shape="pill"
                className="cancel-button"
                onClick={() => handleCancel()}
              />
              <ABB.Button
                className="btn-save"
                type="primary-blue"
                text={"Save"}
                shape="pill"
                onClick={handleSave}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SessionControl;
