import { Delete, Get, Post, Put } from "../utils/apiServices";

const {
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
} = process.env;

export const hostURL = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;
const application_api = `${hostURL}/provisioningapi/api/ApplicationConfiguration`;
const authorization_api = `${hostURL}/authservapi/api`;

const caseCreationTokenEndPoint = `${hostURL}/sfdcapi/api/Case/Token`;
const caseCreationEndPoint = `${hostURL}/sfdcapi/api/Case`;
const getAllApplicationTypeEndPoint = `${application_api}/getAllApplicationType`;
const allapplicationConfigEndPoint = `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationConfig`;
const allappConfigurationEndPoint = `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationsByCategory`;
const getAllApplicationEndPoint = `${authorization_api}/PlatformAdmin/Applications`;
const deleteApplicationConfigEndPoint = `${application_api}/deleteApplicationConfig`;
const getAllPillarEndPoint = `${application_api}/getAllPillar`;
const createApplicationConfigEndPoint = `${application_api}/createApplicationConfig`;
const updateApplicationConfigEndPoint = `${application_api}/updateApplicationConfig`;
const getAllRolesEndPoint = `${authorization_api}/PlatformAdmin/GetAllRoles`;
const addNewRoleEndPoint = `${authorization_api}/PlatformAdmin/Role`;
const getUserDetailsEndPoint = `${authorization_api}/PlatformAdmin/GetAllUsersWithApplicationRoles`;
const deleteAssignedRoleEndPoint = `${authorization_api}/PlatformAdmin/UnassignClientRolesToUser`;
const deleteUserRoleEndPoint = `${authorization_api}/PlatformAdmin/Role`;
const assignClientRolesToUserEndPoint = `${authorization_api}/PlatformAdmin/AssignClientRolesToUser`;
const applicationsByCategoryEndPoint = `${application_api}/getAllApplicationsByCategory`;
const inviteNewUserEndPoint = `${authorization_api}/PlatformAdmin/InviteUser`;
const getUserPreferenceEndPoint = `${hostURL}/provisioningapi/api/UserPreference/getUserPreference`;
const applicationRequestEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/RequestApplicationAccess`;
const userRequestEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/GetFilteredApplicationAccessRequests`;
const getAllRequestStatusesEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/GetAllRequeststatuses`;
const rejectApplicationRequestEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/RejectApplicationAccessRequest`;
const approveApplicationRequestEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/ApproveApplicationAccessRequest`;
const getAllowedRolesEndPoint = `${hostURL}/provisioningapi/api/ApplicationRequest/GetAllowedRolesForRequest`;
const assignServiceAppEndPoint = `${authorization_api}/PlatformAdmin/AssignServiceAppsAccessRoleToUser`;
const getApplicationDetails = `${hostURL}/provisioningapi/api/AuditLog/GetApplicationDetails`;
const getAllUsers = `${hostURL}/authservapi/api/PlatformAdmin/GetAllUsers`;
const getAuditLogs = `${hostURL}/provisioningapi/api/AuditLog/GetAuditLogs`;
const sessionTimeoutvalueAPI = `${hostURL}/authservapi/api/PlatformAdmin/SetSessionTimeoutvalue`;
const getSessionTimeoutValueAPI = `${hostURL}/authservapi/api/PlatformAdmin/GetSessionTimeoutvalue`
export interface IApplicationRequestPayload {
  firstname: string;
  lastname: string;
  username: string;
  appregid: string;
  comment: string;
}

export const caseCreationToken = async (data: any): Promise<any> => {
  const response = await Post<any, any>(caseCreationTokenEndPoint, data, {});
  return response;
};
export const createCase = async (data: FormData): Promise<any> => {
  const response = await Post<any, any>(caseCreationEndPoint, data, {
    headers: {
      accept: "application/json; charset=utf-8",
    },
  });
  return response;
};
// export const createCase = async (
//   data: FormData,
//   headers: HeadersInit
// ): Promise<any> => {
//   const response = await fetch(caseCreationEndPoint, {
//     method: "POST",
//     headers: headers,
//     body: data,
//   });
//   return response;
// };

export const getUserPreferenceService = async (): Promise<any> => {
  const response = await Get<any, {}>(getUserPreferenceEndPoint, {}, {});
  return response;
};

export const getAllApplicationType = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllApplicationTypeEndPoint, {}, {});
  return response;
};

export const getAllApplicationConfig = async (id: any): Promise<any> => {
  const endPoint = `${allapplicationConfigEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllApplicationConfigurations = async (
  id: any
): Promise<any> => {
  const endPoint = `${allappConfigurationEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllApplications = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllApplicationEndPoint, {}, {});
  return response;
};

export const deleteAppltnConfig = async (id: any): Promise<any> => {
  const endPoint = `${deleteApplicationConfigEndPoint}/${id}`;
  const response = await Post<any, {}>(endPoint, {}, {});
  return response;
};

export const getAllPillarData = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllPillarEndPoint, {}, {});
  return response;
};

export const createAppltnConfig = async (data: any): Promise<any> => {
  const response = await Post<any, any>(
    createApplicationConfigEndPoint,
    data,
    {}
  );
  return response;
};

export const updateAppltnConfig = async (data: any): Promise<any> => {
  const response = await Post<any, {}>(
    updateApplicationConfigEndPoint,
    data,
    {}
  );
  return response;
};

export const getAllRoles = async (data: any): Promise<any> => {
  const response = await Post<any, any>(getAllRolesEndPoint, data, {});
  return response;
};

export const addNewRole = async (data: any): Promise<any> => {
  const response = await Post<any, any>(addNewRoleEndPoint, data, {});
  return response;
};

export const getUserDetailsWithAppltnRole = async (data: any): Promise<any> => {
  const response = await Post<any, any>(getUserDetailsEndPoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const deleteAssignedRoleToUser = async (
  client_id: any,
  userid: any,
  userName: any,
  payload: any
): Promise<any> => {
  const endpoint = `${deleteAssignedRoleEndPoint}?client_id=${client_id}&userid=${userid}&userName=${userName}`;
  const response = await Delete<any, any>(endpoint, payload, {});
  return response;
};

export const AssignedRoleToUser = async (
  client_id: any,
  userid: any,
  userName: any,
  payload: any
): Promise<any> => {
  const endpoint = `${assignClientRolesToUserEndPoint}?client_id=${client_id}&userid=${userid}&userName=${userName}`;
  const response = await Post<any, any>(endpoint, payload, {});
  return response;
};
export const deleteUserRole = async (payload: any): Promise<any> => {
  const response = await Delete<any, any>(deleteUserRoleEndPoint, payload, {});
  return response;
};

export const getAllApplicationByCategory = async (id: any): Promise<any> => {
  const endPoint = `${applicationsByCategoryEndPoint}/${id}`;
  const response = await Get<any, {}>(endPoint, {}, {});
  return response;
};

export const inviteNewUser = async (data: any): Promise<any> => {
  const response = await Post<any, any>(inviteNewUserEndPoint, data, {});
  return response;
};
export const requestApplication = async (
  data: IApplicationRequestPayload
): Promise<any> => {
  const response = await Post<any, any>(applicationRequestEndPoint, data, {});
  return response;
};
export const getUserRequests = async (data: any): Promise<any> => {
  const response = await Post<any, any>(userRequestEndPoint, data, {});
  return response;
};
export const getAllStatuses = async (): Promise<any> => {
  const response = await Get<any, {}>(getAllRequestStatusesEndPoint, {}, {});
  return response;
};
export const approveApplication = async (data: any): Promise<any> => {
  const response = await Post<any, any>(
    approveApplicationRequestEndPoint,
    data,
    {}
  );
  return response;
};
export const rejectApplication = async (data: any): Promise<any> => {
  const response = await Post<any, any>(
    rejectApplicationRequestEndPoint,
    data,
    {}
  );
  return response;
};

export const getAllowedRoles = async (data: any): Promise<any> => {
  const response = await Post<any, any>(getAllowedRolesEndPoint, data, {});
  return response;
};

export const assignServiceApp = async (): Promise<any> => {
  const response = await Get<any, any>(assignServiceAppEndPoint, {}, {});
  return response;
};

// activity logs api

export const getApplicationDetailsAPI = async (): Promise<any> => {
  const response = await Get<any, any>(getApplicationDetails, {}, {})
  return response
}

export const getAllUsersAPI = async (): Promise<any> => {
  const response = await Get<any, any>(getAllUsers, {}, {})
  return response
}

export const getAuditLogsAPI = async (payload: any): Promise<any> => {
  const response = await Post<any, any>(getAuditLogs, payload, {});
  return response;
};

export const sessionTimeoutvalue = async (sessionTimeOutInSeconds: any): Promise<any> => {
  const response = await Get<any, any>(`${sessionTimeoutvalueAPI}?sessionTimeOutInSeconds=${sessionTimeOutInSeconds}`, {}, {});
  return response;
};

export const getSessionTimeoutValue = async (): Promise<any> => {
  const response = await Get<any, any>(`${getSessionTimeoutValueAPI}`, {}, {});
  return response;
};
